import { persistStore, persistReducer, } from 'redux-persist'
import persistCombineReducers from 'redux-persist/es/persistCombineReducers'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import detectDeviceReducer from './detectDeviceReducer'
import mapBackingReducer from './mapBackingReducer'
import userReducer from './userReducer'
import { combineReducers, createStore } from 'redux'


// export const rootReducer = combineReducers({
//   user: userReducer,
//   isMobile: detectDeviceReducer,
//   // mapBackings: mapBackingReducer,
// })
export const rootReducer = {
  user: userReducer,
  isMobile: detectDeviceReducer,
  mapBackings: mapBackingReducer,
}

// const customizedMiddleware = getDefaultMiddleware({
//   serializableCheck: false
// })
const persistConfig = {
  key: 'smurno',
  storage,
}

const persistedReducer = persistCombineReducers(persistConfig, rootReducer)

let store = createStore(persistedReducer)
// export let store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//       getDefaultMiddleware({
//         thunk: {},
//         serializableCheck: false,
//       })
// })
let persistor = persistStore(store)
let projectStorage = { store, persistor }

export default projectStorage
